import {defineStore} from 'pinia'
import axios from "axios";
import {useAuthStore} from "@/stores/auth";
import {useUserStore} from "@/stores/user";
import {getIntercomConfig, initIntercom, toggleIntercom} from "@/stores/includes/intercom";
import {checkForNewVersion, handleXSRFError} from "@/stores/includes/interceptors";
import {useToast} from "vue-toastification";
import {useSubscriptionStore} from "@/stores/subscription/subscription-store";
import {red} from "vuetify/util/colors";

const toast = useToast();

export const useAppStore = defineStore('app', {
    state: () => ({
        disabledFeatures: [],
        checkForSubscription: false,
        useMultipleSubscriptions: false,
        registrationRequiresToken: true,
        enableTeamAdminCoverage: false,
        intercomVisible: false,
        intercomBooted: false,
        intercomConfig: {},
        informedForUpdate: false,
        informForUpdateDialog: false,
    }),
    getters: {
        isFeatureEnabled: (state) => {
            return (feature) => !state.disabledFeatures.includes(feature)
        },
    },
    actions: {
        /**
         * Inits the central app store, should be called directly in the main.js
         *
         * @returns {Promise<void>}
         */
        async init() {

            console.log("Starting init of app store")

            // --------------
            // AXIOS SETUP
            // --------------

            // These are defaults we set on the beginning of the project, I am scared to change or remove them
            // since it may also change the way how we talk to Tremendous and api.video etc... so we need to
            // retest everything!
            axios.defaults.withXSRFToken = true
            axios.defaults.headers.post['Content-Type'] = 'application/json'
            // Stuff we need so we don't get a 419 XSRF error on logout (only on logout, why? :D)
            axios.defaults.withCredentials = true

            // Add interceptor for XSRF refreshing and version update checking
            axios.interceptors.response.use(
                (response) => checkForNewVersion(response),
                (error) => handleXSRFError(error)
            )

            // ------------------
            // App initialization
            // ------------------

            // Load app config
            await this.loadConfig()

            // Init auth, success defines if the user already has a active session
            const auth = useAuthStore()
            await auth.init()

            // Boot the store
            await this.boot()

            console.log("Initializing app store finished")
        },
        /**
         * Boots the central app store so we can use the app
         *
         * @returns {Promise<boolean>}
         */
        async boot() {
            let redirected = false;

            console.log("Booting app store")

            const subscriptions = useSubscriptionStore()
            const user = useUserStore()
            const auth = useAuthStore()

            console.log("State of authenticated is " + auth.authenticated)

            // When use is logged in
            if (auth.authenticated) {
                console.log("User logged in loading details and updating dd context")

                // Get user
                await user.get()

                // load user subscriptions
                await subscriptions.load();

                // Redirect if user is not registered correctly
                redirected = await user.redirectOnIncompleteRegistration()

                // if the user is not already redirected through the registration process check subscription
                if(!redirected) {
                    redirected = subscriptions.redirectOnInvalidSubscription();
                }
            }
            // Refresh intercom
            await this.initIntercom()

            console.log("Booting app store finished")

            return !redirected;
        },
        /**
         * Loads the frontend config from the backend
         *
         * @returns {Promise<void>}
         */
        async loadConfig() {
            try {
                const {
                    data: { data: {
                    disabledFeatures,
                    checkForSubscription,
                    useMultipleSubscriptions,
                    registrationRequiresToken,
                    enableTeamAdminCoverage,
                }}} = await axios.get('api/app/config')

                this.disabledFeatures = disabledFeatures;
                this.checkForSubscription = checkForSubscription;
                this.useMultipleSubscriptions = useMultipleSubscriptions;
                this.registrationRequiresToken = registrationRequiresToken;
                this.enableTeamAdminCoverage = enableTeamAdminCoverage;
            } catch (e) {
                console.error("Error while loading init config for frontend")
                toast.error("Fehler beim initialisieren der App")
            }
        },
        /**
         *
         */
        informUserNewVersionAvailable() {

            // If already informed, skip to avoid annoying the user on every request
            if (this.informedForUpdate) return;

            // Inform user
            this.informedForUpdate = true;
            this.informForUpdateDialog = true;
        },
        /**
         *
         * INTERCOM FUNCTIONALITY
         *
         */
        async initIntercom() {
            await initIntercom(this)
        },
        async toggleIntercom() {
            await toggleIntercom(this)
        },
        async getIntercomConfig() {
            await getIntercomConfig(this)
        },
    }
})