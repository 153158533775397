<script setup lang="ts">

    import {useAppStore} from "@/stores/app";
    import TsButton from "@/components/app/TsButton.vue";

    // Uses
    const app = useAppStore();

    // State
    const countdown = ref(60)

    // Hooks
    onMounted(() => {
        // Rare case szenario => When app loads, component not mounted yet, but value already to true (init request for frontend)
        //                       the watch would not trigger, so we check here
        if (app.informForUpdateDialog) {
            countDownTheDownCounter();
        }
    })
    
    // Methods
    const abort = () => {
        app.informForUpdateDialog = false;
    }
    
    const update = () => {
        window.location.reload();
    }
    
    const countDownTheDownCounter = () => {
        // If dialog is closed skip the counter
        if (!app.informForUpdateDialog) return;

        countdown.value--;
        if (countdown.value <= 0) {
            update();
        }

        setTimeout(() => {
            countDownTheDownCounter();
        }, 1000);
    }

    // Watches
    // Watches if the dialog pops up to start the countdown
    watch(() => app.informForUpdateDialog, (newValue) => {
        console.log(`Dialog value changed to ${newValue}`)
        if (newValue) {
            countDownTheDownCounter();
        }
    });

</script>

<template>
    <v-dialog :model-value="app.informForUpdateDialog" max-width="700">
        <v-card title="Neues Update verfügbar" class="rounded-xl">
            <v-card-text>
                <p>Eine neue TellScale Version ist verfügbar 🎉</p>
                <p>Lade jetzt die App neu, um von den neuesten Features zu profitieren!</p>
                <v-alert type="warning" text="Nicht gespeicherte Änderungen können verloren gehen!"></v-alert>
            </v-card-text>
            <v-card-actions>
                <ts-button type="secondary" verified @click="abort">
                    Abbrechen
                    <template #verify>
                        <p>Du kannst die App jederzeit aktualisieren, wenn du einfach die Seite in deinem Browser neu lädst!</p>
                        <v-alert type="warning" text="Wenn du die App weiter benutzt kann es zu Fehlern kommen!"></v-alert>
                    </template>
                </ts-button>
                <v-spacer></v-spacer>
                <ts-button @click="update">Update in {{ countdown }}</ts-button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped lang="scss">

</style>