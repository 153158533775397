import {defineStore} from 'pinia';
import {useToast} from "vue-toastification";

import {Subscription, Feature } from "@/stores/subscription/subscription-model";
import {Price} from "@/stores/subscription/price-model";

// @ts-ignore
import {useAuthStore} from "@/stores/auth";
// @ts-ignore
import {useAppStore} from "@/stores/app";
import {useUserStore} from "@/stores/user"

import axios from 'axios';

const toast= useToast();

export enum Interval {
  Month = 'monatlich',
  Year = 'jährlich',
}

export enum SwapMethod {
  Upgrade = 'Upgrade',
  Downgrade = 'Downgrade',
  Undefined = 'Auswahl',
}

export const useSubscriptionStore = defineStore('subscriptions', {
  state: (): { scheduled?: { plan: Subscription, calculation: number }, subscriptions: Subscription[] } => ({
      subscriptions: [],
      scheduled: undefined
  }),

  getters: {
    checkForSubscription: (): boolean => {
      const app = useAppStore()

      return app.checkForSubscription
    },
    inSwap: (state): boolean => {
      return !!state.scheduled;
    },
    inGracePeriod(): boolean {
      return this.getActiveSubscription()?.stripe?.ends_at && !this.inSwap;
    },
    pastDue(): boolean {
      return this.getActiveSubscription()?.stripe?.stripe_status === 'past_due';
    },
  },

  actions: {
    getActiveSubscription(): Subscription | undefined {
      return this.subscriptions.find(subscription => subscription.isActive);
    },

    getTeamMembershipSubscription(): Subscription | undefined {
      return this.subscriptions.find(subscription => subscription.values.use_for_team_membership);
    },

    getRegularSubscriptions(): Subscription[] {
      return this.subscriptions.filter(subscription => subscription.values.visible);
    },

    redirectOnInvalidSubscription(): boolean {
      // If valid subscription available dont redirect
      if (!this.checkForSubscription) return false
      if (this.getActiveSubscription()) return false

      // If no valid subscription available, redirect to payment-selection page to finish it up
      let route = 'payment-selection'

      console.log("Redirecting to " + route)

      // Redirect
      // @ts-ignore
      this.router.replace('/' + route)

      return true;
    },

    collectAllFeatures(subscriptions: Subscription[]): Feature[] {
      let features = subscriptions.flatMap(subscription => subscription.values.all_features);

      const names = [...new Set(features.map(feature => feature.name))];

      return names.map(name => features.find(feature => feature.name === name)) as Feature[];
    },

    async load(): Promise<void> {
      try {
        const subscriptionsResponse = await axios.get('/api/subscription');

        const subscriptions = Subscription.validateAndCreateMany(subscriptionsResponse.data);

        let features = this.collectAllFeatures(subscriptions)

        const auth = useAuthStore()

        if(auth.authenticated) {
          const userSubscriptionResponse = await axios.get<{
            current: { plan: any, price: any, stripe: any, },
            scheduled: { plan: any, price: any, calculation: any }
          }>('/api/user/subscription');

          if(userSubscriptionResponse.data.scheduled) {
            this.scheduled = {
              plan: Subscription.validateAndCreate(userSubscriptionResponse.data.scheduled.plan),
              calculation: userSubscriptionResponse.data.scheduled.calculation,
            }

            this.scheduled.plan.pricePreview = Price.validateAndCreate(userSubscriptionResponse.data.scheduled.price);
          }
          else {
            this.scheduled = undefined;
          }

          if(userSubscriptionResponse.data.current) {
            const currentSubscription = Subscription.validateAndCreate(userSubscriptionResponse.data.current.plan);

            let selectedSubscription = subscriptions.find(subscription => subscription.values.id == currentSubscription.values.id);

            // Add the current subscription of the user to the array of visible subscriptions
            if (!selectedSubscription) {
              selectedSubscription = currentSubscription;
              subscriptions.push(selectedSubscription);

              features = this.collectAllFeatures(subscriptions);
            }

            selectedSubscription.isActive = true;
            selectedSubscription.priceUsed = Price.validateAndCreate(userSubscriptionResponse.data.current.price);
            selectedSubscription.stripe = userSubscriptionResponse.data.current.stripe;
          }
        }

        subscriptions.forEach(subscription => {
          const resolvedFeatures = subscription.values.all_features;

          subscription.missingFeatures = features.filter(feature => {
            return resolvedFeatures.find(resolvedFeature => feature.name === resolvedFeature.name) === undefined
          });
        })

        if(this.scheduled) {
          const resolvedFeatures = this.scheduled.plan.values.all_features;

          this.scheduled.plan.missingFeatures = features.filter(feature => {
            return resolvedFeatures.find(resolvedFeature => feature.name === resolvedFeature.name) === undefined
          });
        }

        this.subscriptions = subscriptions;
      }
      catch(error) {
          console.log(error);
      }
    },

    async preview(token: number): Promise<Subscription | undefined> {
      try {
        const response = await axios.post<{ plan: any, price: any }>('api/subscription/preview', { token: token });

        const subscription = Subscription.validateAndCreate(response.data.plan);

        subscription.pricePreview = Price.validateAndCreate(response.data.price);

        const activeSubscription = this.getActiveSubscription();

        if(activeSubscription?.values.id == subscription.values.id) {
          subscription.priceUsed = activeSubscription.priceUsed;
          subscription.isActive = true;
        }

        const features = this.collectAllFeatures(this.subscriptions as Subscription[]);

        const resolvedFeatures = subscription.values.all_features;

        subscription.missingFeatures = features.filter(feature => {
          return resolvedFeatures.find(resolvedFeature => feature.name === resolvedFeature.name) === undefined
        });

        return subscription;
      }
      catch(error) {
        console.error(error);

        toast.error('Der angegebene Token ist leider ungülitg, überprüfe den Token und versuche es erneut!');
      }
    },

    async select(price: Price, token: number | null = null): Promise<void> {
      try {
        await axios.post('api/user/subscription/select', { price_id: price.values.id, token: token });

        await this.load();

        const userStore = useUserStore();

        await userStore.get();

        toast.success('Das Abonnement wurde erfolgreich ausgewählt!');
      }
      catch(error) {
        console.error(error);

        toast.error('Fehler beim Auswählen des Abonnements');
      }
    },

    async checkForSwapMethod(price: Price): Promise<SwapMethod | null> {
      try {
        const { data: { swap_method } } = await axios.post<{ swap_method: SwapMethod }>('api/user/subscription/check-swap', { price_id: price.values.id });

        return swap_method;
      }
      catch(error) {
        console.error(error);

        toast.error('Fehler beim Überprüfen der Auswahl');

        return null;
      }
    },

    async getSwapDetails(price: Price, swapMethod: SwapMethod, userMail?: string ): Promise<{ swap_date: string, amount_total: number, scheduled: boolean } | null> {
      try {
        const { data } = await axios.post<{
          swap_date: string,
          amount_total: number,
          scheduled: boolean,
        }>('api/user/subscription/swap-details', { price_id: price.values.id, swap_method: swapMethod, user_mail: userMail });

        return data;
      }
      catch(error: any) {
        console.error(error);

        toast.error(error.message);

        return null;
      }
    },
    async loadCancelData(): Promise<{ promotors: number, links: number } | null> {
      try {
        const { data : { data } } = await axios.get<{ data:
          {
            promotors: number;
            links: number;
          }
        }>('api/subscription/cancel/preview');

        return data
      }
      catch(error: any) {
        console.error(error);

        toast.error("Fehler beim laden der Vorschau Daten");

        return null
      }
    },
    async cancelSubscription(): Promise<void> {
      try {
        await axios.post('api/subscription/cancel');
        toast.success("Abo erfolgreich gekündigt")
      }
      catch(error: any) {
        console.error(error);

        toast.error("Fehler beim kündigen des Abos");
      }
    },
    async cancelSwap(): Promise<void> {
      try {
        await axios.post('api/subscription/swap/cancel');
        await this.load()
        toast.success("Abo Umstellung abgebrochen")
      }
      catch(error: any) {
        console.error(error);

        toast.error("Fehler beim abbrechen der Abo Umstellung");
      }
    },
    async reactivateSubscription(): Promise<void> {
      try {
        await axios.post('api/subscription/reactivate');
        toast.success("Abo erfolgreich reaktiviert")
      }
      catch(error: any) {
        console.error(error);

        toast.error("Fehler beim reaktivieren des Abos");
      }
    },
  },
});