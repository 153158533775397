<script setup>
import { useThemeConfig } from '@core/composable/useThemeConfig'
import UpdateAvailableDialog from "@/components/app/Dialogs/UpdateAvailableDialog.vue";

const {
  syncInitialLoaderTheme,
  syncVuetifyThemeWithTheme: syncConfigThemeWithVuetifyTheme,
  isAppRtl
} = useThemeConfig()

// ℹ️ Sync current theme with initial loader theme
syncInitialLoaderTheme()
syncConfigThemeWithVuetifyTheme()
</script>

<template>
  <VLocaleProvider :rtl="isAppRtl">
    <VApp>
      <VMain>
        <update-available-dialog></update-available-dialog>
        <RouterView />
      </VMain>
    </VApp>
  </VLocaleProvider>
</template>