<script setup lang="ts">
    import { useSubscriptionStore } from "@/stores/subscription/subscription-store";
    import TsButton from "@/components/app/TsButton.vue";

    const router = useRouter();
    const subscriptionStore = useSubscriptionStore();

    defineProps<{
        modelValue: boolean,
    }>();

    const emit = defineEmits<{
        (e: 'update:modelValue', value: boolean),
    }>();

    const title = computed(() => {
        return subscriptionStore.pastDue ? 'Nutzung Eingeschränkt' : 'Premium Feature';
    });

    const redirectButtonText = computed(() => {
        return subscriptionStore.pastDue ? 'Zahlungsmethode aktualisieren' : 'Jetzt upgraden';
    });

    const redirect = async () => {
        if(subscriptionStore.pastDue) {
            await router.push('/setting/payment');
        }
        else {
            await router.push('/setting/select-subscription');
        }

        emit('update:modelValue', false);
    }
</script>

<template>
    <VDialog
        v-model="modelValue"
        width="800"
        persistent
    >
        <VCard :title="title" class="pa-3">
            <VCardText v-if="!subscriptionStore.pastDue" class="mb-7">
                Dieses Feature ist in deinem aktuellen Abonnement nicht enthalten.
                Um vollen Zugriff auf bestimmte Funktionen zu erhalten, kannst du dein Abonnement jederzeit upgraden.
            </VCardText>

            <VCardText v-else class="mb-7">
                Aufgrund einer überfälligen Zahlung deines Abonnements wurde die
                Nutzung von TellScale vorübergehend eingeschränkt.<br><br>

                Um den vollen Zugriff auf TellScale wiederherzustellen, bitten wir dich,
                deine Zahlungsmethode zu aktualisieren.
            </VCardText>

            <VCardActions class="demo-space-x">
                <TsButton color="primary" @click="redirect">
                    {{ redirectButtonText }}
                </TsButton>

                <TsButton color="primary" @click="emit('update:modelValue', false)">
                    Nicht jetzt
                </TsButton>
            </VCardActions>
        </VCard>
    </VDialog>
</template>

<style scoped lang="scss">

</style>